import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  logoLabel: {
    id: 'shared.logo.a11yLabel',
    defaultMessage: 'Home, Starbucks',
    description: 'Aria label for link to homepage',
  },
  star: {
    id: 'shared.commonWords.star',
    description: 'the singular word for a reward star',
    defaultMessage: 'Star',
  },
  stars: {
    id: 'shared.commonWords.stars',
    description: 'the plural word for reward stars',
    defaultMessage: 'Stars',
  },
  starbucksRewardsTrademark: {
    id: 'shared.commonWords.starbucksRewardsTrademark',
    defaultMessage: 'Starbucks® Rewards',
    description: 'Starbucks Rewards shared message',
  },
  success: {
    id: 'shared.commonWords.success',
    defaultMessage: 'Success',
    description: 'The word for when an action has been successful',
  },
  privacyNotice: {
    id: 'shared.commonWords.privacyNotice',
    defaultMessage: 'Privacy Notice',
    description: 'text of link to privacy notice',
  },
  menuLabel: {
    id: 'shared.commonWords.menuLabel',
    defaultMessage: 'Menu',
    description: 'Label for menu nav or products',
  },
  needHelp: {
    id: 'shared.commonWords.needHelp',
    defaultMessage: 'Need help?',
    description: 'Label for link to need help?',
  },
  tipLabel: {
    id: 'shared.commonWords.tipLabel',
    description: 'Label for tip',
    defaultMessage: 'Tip',
  },
  termsOfUse: {
    id: 'shared.commonWords.TermsOfUse',
    defaultMessage: 'Terms of Use',
    description: 'text of link to terms of use notice',
  },
  updateSuccessful: {
    id: 'shared.commonWords.updateSuccessful',
    defaultMessage: 'Update Successful',
    description: 'text for update successful notificiation',
  },
  default: {
    id: 'shared.commonWords.default',
    defaultMessage: 'Default',
    description: 'Indicates default value',
  },
  customized: {
    id: 'shared.commonWords.customized',
    defaultMessage: 'Customized',
    description: 'Indicates customized value',
  },
  maximumReached: {
    id: 'shared.commonWords.maximumReached',
    defaultMessage: 'maximum reached',
    description: 'Indicates maximum value reached',
  },
  total: {
    id: 'shared.commonWords.total',
    defaultMessage: 'Total',
    description: 'Indicates a purchase total',
  },
  copied: {
    id: 'shared.commonWords.copied',
    defaultMessage: 'Copied!',
    description: 'Indicated when text is copied to clipboard',
  },
  linkCopied: {
    id: 'shared.commonWords.linkCopied',
    defaultMessage:
      "{isEmail, select, true {{recipientName}'s l} other {L}}ink copied!",
    description: 'Another message to indicate when text is copied to clipboard',
  },
  eGiftHistory: {
    id: 'shared.commonWords.eGiftHistory',
    description: 'message for eGift History',
    defaultMessage: 'eGift History',
  },
  pickupLabel: {
    id: 'shared.commonWords.pickupLabel',
    defaultMessage: 'Pickup',
    description: 'Pickup label for in-app delivery messages',
  },
  deliveryLabel: {
    id: 'shared.commonWords.deliveryLabel',
    defaultMessage: 'Delivery',
    description: 'Delivery label for in-app delivery messages',
  },
  loadingAnnouncement: {
    id: 'shared.commonWords.loadingAnnouncement',
    defaultMessage: 'Loading',
    description: 'Announcement to indicate when something is loading',
  },
  unavailable: {
    id: 'shared.commonWords.unavailable',
    defaultMessage: 'Unavailable',
    description:
      'A message alerting the user that something is unavailable at the moment.',
  },
  loadingWithEllipsis: {
    id: 'shared.commonWords.loadingWithEllipsis',
    defaultMessage: 'Loading...',
    description:
      'Announcement to indicate when something is loading followed by an ellipsis',
  },
});
